import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function JAMStackBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform container__heading--no-logo">
              JAM Stack{" "}
            </h1>
            <h2 className="container__sub-heading container__sub-heading--platform">
              JAM Stack is an architecture pattern to build Websites and Apps in
              a modern way and one that delivers better performance.
            </h2>
            <h6 className="container__content">
              Hashout, a company focused on building Digital Experience using
              best-of-breed technology and platforms, has designed and delivered
              micro-sites to large-scale sites and applications on JAM Stack.
            </h6>
            <a
              className="container__link"
              href="/blog/JAMStack-for-Authorable-content-Centric-Sites"
              target="_blank"
            >
              CLICK HERE TO SEE IF JAM STACK IS A GOOD FIT FOR YOUR USECASE?
            </a>
            <button
              className="button container__button container__button--platform"
              onClick={openModal}
            >
              CONTACT US
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/jam-stack.png"
              alt="JAM Stack"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Jam-stack-Banner"
        page="Jam-stack"
       
      />
    </section>
  );
}

export default JAMStackBanner;
