import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
// import AdobeCommerceMagento from "../components/Platforms/AdobeCommerceMagento/AdobeCommerceMagento";
// import AdobeCommerceMagentoOpenSource from "../components/Platforms/AdobeCommercemagentoOpensource/AdobeCommercemagentoOpensource";
import JAMStackBanner from "../components/Platforms/JAMStackBanner/JAMStackBanner";
import CoreJamStack from "../components/Platforms/Core-of-JAMStack/Core-of-JAMStack";
import JAMStackBenefits from "../components/Platforms/JAMStack-Benefits/JAMStackBenefits";
import JAMStackExplore from "../components/Platforms/JAMStack-explore/JAMStackexplore";
import OurProjects from "../components/Solutions/OurProjects_MobileSolutions/OurProjects";
import { Helmet } from "react-helmet";
function JamStack() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies - JAM Stack
       </title>
       <meta
         name="description"
         content="JAM Stack is an architecture pattern to build Websites and Apps in a modern way and one that delivers better performance."
       ></meta></Helmet>
      <SolutionsHelmet />
      {/* <AdobeCommerceMagento />
      <AdobeCommerceMagentoOpenSource />
      <MagentoAdobe /> */}
      <JAMStackBanner />
      <CoreJamStack />
      <OurProjects />
      <JAMStackBenefits />
      <JAMStackExplore />
      <LearnAboutUs page="jam-stack"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default JamStack;
