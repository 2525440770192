import React from "react";

function JAMStackBenefits() {
  return (
    <section className="section section--tools section--adobe-benefits section--bg-light-blue --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">JAM Stack Site Benefits</h2>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/high-page-load-performance.png"
                alt="High Page Load Performance"
              />
              <a>
                <h5 className="card__title">High Page Load Performance</h5>
              </a>
              <p className="card__description">
                everything is pre-rendered HTML with dynamic functionality
                powered by APIs
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/cleaner-separation.png"
                alt="Cleaner separation of content from code"
              />
              <a>
                <h5 className="card__title">
                  Cleaner separation of content from code
                </h5>
              </a>
              <p className="card__description">
                Makes it easy to evolve technology independently
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/modern-technology-stack.png"
                alt="Modern technology stack"
              />
              <a>
                <h5 className="card__title">Modern technology stack</h5>
              </a>
              <p className="card__description">
                Ability to use React, Vue, and GO based static site which makes
                it both fun and efficient to build along with Git-based
                workflows and CDN for hosting which makes it highly secure too.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/faster-build.png"
                alt="Faster to build"
              />
              <a>
                <h5 className="card__title">Faster to build</h5>
              </a>
              <p className="card__description">
                several great tools and frameworks available to bootstrap and
                build. Easy to use CI/CD pipelines to provide branch-level
                continuous deployments increasing the speed and efficiency of
                development.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/highly-optimized-cost.png"
                alt="Highly optimized on cost"
              />
              <a>
                <h5 className="card__title">Highly optimized on cost</h5>
              </a>
              <p className="card__description">
                In most cases, CDN is the only hosting cost since everything is
                pre-rendered which eliminates the need for expensive servers
                with redundancy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JAMStackBenefits;
