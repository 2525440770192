import React from "react";

function JAMStackExplore() {
  return (
    <section className="section section--blog --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h2 className="container__heading">
              Are you ready to explore JAMStack?
            </h2>
            <p className="container__content">
              JAMStack is undoubtedly an amazing and modern way to build
              websites. However, it is important to choose JAMStack for the
              right use case. You should also pick the appropriate
              tools/platforms to build, and host to create a seamless
              high-performing experience for your customers.
            </p>
            <p className="container__content">
              Are you facing performance, scalability issues with your website?
              Do you have a complex and legacy CMS platform that is difficult to
              change and innovate? We strongly recommend you look at this modern
              stack to power your customer experience.
            </p>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="--bg-absolute-right"
              src="../../solutions/platforms/jam-stack-explore.png"
              alt="jam-stack-explore"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default JAMStackExplore;
