import React from "react";
import Modal from "react-modal";
import ContactUsForm from "../ContactUs/ContactUsForm";
import ContactPopUpStyles from "./ContactPopUp.module.scss";

function ContactPopUp(props) {
	const { url, page, section } = props;
	
	return (
		<Modal
			isOpen={props.modalIsOpen}
			onRequestClose={props.onRequestClose}
			className={ContactPopUpStyles.modalOpen}
			overlayClassName={ContactPopUpStyles.modalOverlay}
			{...props}
		>
			<div className={ContactPopUpStyles.modalHeader}>
				<img onClick={props.onRequestClose} src="../../Close.svg"></img>
				<img onClick={props.onRequestClose} src="../../CloseMobile.svg"></img>
			</div>
			<div className={ContactPopUpStyles.modalBody}>
				<div className={ContactPopUpStyles.modalTitle}>
					<h2>{props.title}</h2>
					<p>{props.subTitle}</p>
				</div>
				<div className={ContactPopUpStyles.modalForm}>
					<ContactUsForm url={url ? url : typeof window !== "undefined" && window.location.href} page={page} section={section} />
				</div>
			</div>
		</Modal>
	);
}

export default ContactPopUp;