import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./Core-of-JAMStack.module.scss";
// import styles from "./Core.module.scss";

function CoreJamStack() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 2,
      slideBy: 2,
    },
    992: {
      items: 3,
      slideBy: 3,
    },
    1200: {
      items: 3,
      slideBy: 3,
    },
  };
  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
  };
  return (
    <section
      className={`${styles["section"]} ${styles["section__projects"]} ${styles["section__jam_stack"]} section--bg-dot-curves ${styles["section__pad_top_0"]} ${styles["__section_large_tb_padding"]}`}
    >
      <div className={`${styles["container"]}`}>
        <div className={`${styles["container__row"]}`}>
          <div
            className={`${styles["container__col_md_7"]} ${styles["container__col_xs_12"]}`}
          >
            <h2 className={"container__heading"}>
              Core of JAMStack are static site generators, some of Hashout’s
              expertise includes
            </h2>
          </div>

          {typeof window !== "undefined" && OwlCarousel && (
            <OwlCarousel
              className={`${styles["owl_theme"]} ${styles["owl_carousel"]} ${styles["jam_stack_carousel"]}`}
              margin={50}
              autoplay={true}
              autoplayTimeout={6000}
              autoplaySpeed={1000}
              autoplayHoverPause={true}
              nav={true}
              dots={true}
              stagePadding={10}
              onInitialized={counter}
              onTranslated={counter}
              responsive={carouselResponsive}
            >
              <div className={`${styles["owl_carousel__item"]} `}>
                <div className="container__col_md_4">
                  <div className={`${styles["card"]} `}>
                    <img
                      className={`${styles["card__image"]} `}
                      src="../../solutions/platforms/jamstack/jerkyll.png"
                      alt="Jerkyll"
                    />
                    <h5 className={`${styles["card__title"]} `}>Jerkyll</h5>
                    <div className={`${styles["card__description"]} `}>
                      Build static websites from dynamic components such as
                      templates, partials, liquid code, markdown, etc.
                    </div>
                    <a
                      className={`${styles["card__action"]} ${styles["__action_link"]} `}
                      href="javascript:void(0);"
                      onClick={openModal}
                    >
                      start a project
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]} `}>
                <div className="container__col_md_4">
                  <div className={`${styles["card"]} `}>
                    <img
                      className={`${styles["card__image"]} `}
                      src="../../solutions/platforms/jamstack/gatsby.png"
                      alt="Gatsby"
                    />
                    <h5 className={`${styles["card__title"]} `}>Gatsby</h5>
                    <div className={`${styles["card__description"]} `}>
                      Build and deploy headless websites that drive more
                      traffic, convert better, and earn more revenue!
                    </div>
                    <a
                      className={`${styles["card__action"]} ${styles["__action_link"]} `}
                      href="javascript:void(0);"
                      onClick={openModal}
                    >
                      start a project
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <div className={"container__col_md_4"}>
                  <div className={`${styles["card"]} `}>
                    <img
                      className={`${styles["card__image"]} `}
                      src="../../solutions/platforms/jamstack/next-js.png"
                      alt="NextJS"
                    />
                    <h5 className={`${styles["card__title"]} `}>NextJS</h5>
                    <div className={`${styles["card__description"]} `}>
                      Best developer experience with all the features you need
                      for production: hybrid static & server rendering,
                      TypeScript support, smart bundling, route pre-fetching,
                      and more. No config needed.
                    </div>
                    <a
                      className={`${styles["card__action"]} ${styles["__action_link"]} `}
                      href="javascript:void(0);"
                      onClick={openModal}
                    >
                      start a project
                    </a>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          )}
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Core-of-JamStack"
        page="JamStack"
       
      />
    </section>
  );
}

export default CoreJamStack;
