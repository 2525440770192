import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function LearnAboutUs(props) {
  const {page} = props;
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        window.location.pathname === "/ux-design" ||
        window.location.pathname === "/product-development" ||
        window.location.pathname === "/adobe-experience-manager" ||
        window.location.pathname === "/adobe-experience-manager-forms" ||
        window.location.pathname === "/salesforce-experience-cloud" ||
        window.location.pathname === "/jam-stack" ||
        window.location.pathname === "/headless-cms" ||
        window.location.pathname === "/adobe-analytics" ||
        window.location.pathname === "/adobe-target" ||
        window.location.pathname === "/adobe-campaign" ||
        window.location.pathname === "/adobe-commerce-magento"
      ) {
        setBackground("--bg-gray-lightest");
      } else {
        setBackground("--bg-white");
      }
    }
  });
  return (
    <section
      className={`section section--lets-connect --section-small-tb-padding ${background}`}
    >
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 container__col-md-offset-3 container__col-xs-12 --center">
            <h2 className="container__heading">
              Would you like to learn more about our services?
            </h2>
            <a className="card__action --action-link" href="#" onClick={openModal}>
              Let's Connect
            </a>
          </div>
        </div>
      </div>
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="LearnAboutUs"
        page={page}
       
      />
    </section>
    // <section
    // 	className={`${styles["section"]} ${styles["section__lets_connect"]} ${styles["__bg_white"]} ${styles["__section_small_tb_padding"]}`}
    // >
    // 	<div className={`${styles["container"]}`}>
    // 		<div className={`${styles["container__row"]}`}>
    // 			<div
    // 				className={`${styles["container__col_md_6"]} ${styles["container__col_md_offset_3"]} ${styles["container__col_xs_12"]} ${styles["__center"]}`}
    // 			>
    // 				<h2 className={`${styles["container__heading"]}`}>
    // 					Would you like to learn more about our services?
    // 				</h2>
    // 				<a
    // 					className={`${styles["card__action"]} ${styles["__action_link"]}`}
    // 					href=""
    // 				>
    // 					Let's Connect
    // 				</a>
    // 			</div>
    // 		</div>
    // 	</div>
    // </section>
  );
}

export default LearnAboutUs;
